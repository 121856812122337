import React from 'react'
import { Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import '../../styles/car-item.css'

const EmiratesCarItem = (props) => {const {imgUrl, model, carName, automatic, speed, price} = props.item

const phoneNumber = '0203 376 5552'

    const makePhoneCall = () => {
        window.location.href = `tel:${phoneNumber}`;
    }

return <Col lg='3' md='4' sm='6' className='mb-5'>
      <div className='car__item'>
          <div className='car__img'>
              <img src={imgUrl} alt='' className='w-100' />
          </div>

          <div className='car__item-content mt-4'>
              <h4 className='section__title text-center'>{carName}</h4>
              <h6 className='rent__price text-center mt-'>£{price}.00<span>/Person</span></h6>

              <div className='car__item-info d-flex align-items-center justify-content-between mt-3 mb-4'>
                  <span className='d-flex align-items-center gap-1'>
                      <i style={{color: '#e32f37'}} class="ri-plane-line"></i>{model}
                  </span>
                  {/* <span className='d-flex align-items-center gap-1'>
                      <i class="ri-settings-2-line"></i>{automatic}
                  </span>
                  <span className='d-flex align-items-center gap-1'>
                      <i class="ri-timer-flash-line"></i>{speed}
                  </span> */}
              </div>

                  <button style={{background: '#262626cc'}} className='w-50 car__item-btn car__btn-rent' onClick={makePhoneCall}>
                      {/* <Link to={`/flights/${carName}`}>Call Now</Link> */}
                      <Link>Call Now</Link>
                  </button>

                  <button style={{background: '#e32f37'}} className='w-50 car__item-btn car__btn-details' >
                      {/* <Link to={`/flights/${carName}`}>Book Now</Link> */}
                      <Link>Book Now</Link>
                  </button>

          </div>
      </div>
</Col>
}

export default EmiratesCarItem